<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :fill="fill"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
  >
    <path
      d="M236.303,413.388c-69.068,103.681 -106.366,228.935 -103.883,366.245c6.42,355.097 299.926,638.177 655.023,631.757c91.008,-1.646 177.079,-22.136 254.929,-57.708c25.1,-11.468 54.789,-0.401 66.257,24.698c11.469,25.1 0.402,54.789 -24.698,66.258c-89.991,41.119 -189.479,64.834 -294.681,66.736c-410.279,7.418 -749.396,-319.654 -756.814,-729.933c-3.02,-167.041 45.321,-318.503 133.329,-441.909l-86.641,-90.715l279.264,-33.369l-46.16,277.436l-75.925,-79.496Zm1089.85,688.044c61.064,-100.5 95.329,-218.896 93.048,-345.065c-6.421,-355.097 -299.927,-638.177 -655.023,-631.757c-81.142,1.467 -158.526,17.912 -229.55,46.672c-25.578,10.358 -54.754,-2 -65.111,-27.578c-10.357,-25.579 2,-54.754 27.578,-65.111c82.08,-33.237 171.503,-52.271 265.275,-53.967c410.279,-7.418 749.396,319.654 756.814,729.933c2.775,153.433 -41.234,296.912 -118.872,416.818l91.084,85.911l-277.142,47.893l31.622,-279.466l80.277,75.717Zm-616.472,-812.391l325.004,-42.605c7.35,-0.963 14.6,2.382 18.637,8.6c4.036,6.218 4.141,14.202 0.268,20.524l-244.967,399.914l224.488,-19.351c7.728,-0.667 15.101,3.374 18.697,10.247c3.597,6.873 2.714,15.233 -2.239,21.203l-507.73,611.981c-6.053,7.296 -16.52,9.095 -24.661,4.239c-8.142,-4.857 -11.532,-14.921 -7.988,-23.714l173.508,-430.5l-165.485,14.668c-6.559,0.581 -12.96,-2.233 -16.967,-7.459c-4.006,-5.225 -5.061,-12.137 -2.796,-18.32l196.667,-536.968c2.464,-6.727 8.461,-11.528 15.564,-12.459Z"
    />
  </svg>
</template>

<script>
export default {
  name: "RepairRecycleResaleIcon",
  props: {
    width: {
      type: [Number, String],
      default: 40,
    },
    height: {
      type: [Number, String],
      default: 40,
    },
    fill: {
      type: String,
      default: "#5a3d86",
    },
    viewBox: {
      type: [Number, String],
      default: "0 0 1536 1536",
    },
  },
};
</script>
